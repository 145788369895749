<template>
  <form v-if="form && categories" class="space-y-6 w-full pb-3">

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 sm:col-span-6">
        <Autocomplete placeholder="Kategorija" v-model="form.internal_category" :items="categories" :showItems="true" />
      </div>

      <div class="col-span-12 sm:col-span-6 flex items-center pt-5">
        <Checkbox text="Importēt produktus" v-model="form.import_products" />
      </div>
    </div>

    <div class="">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="close">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import Autocomplete from "@/components/Components/Autocomplete";
import Checkbox from "@/components/Components/Checkbox";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditSupplierCategory",
  components: {
    Autocomplete,
    Checkbox,
    Loading
  },
  props: ["category"],
  data: () => ({
    form: null,
  }),
  created() {
    this.$store.dispatch('fetchCategories', {
      ignoreId: this.category.id
    })
  },
  mounted() {
    this.form = this.category
  },
  computed: {
    ...mapGetters({
        categories: 'fetchedCategories',
        loading: "loading",
        errors: "errors",
    }),
    formValidation() {
      return {
        // name: {
        //   rules: ['required']
        // },
      }
    }
  },
  methods: {
    close() {
      this.$store.dispatch("clearEditableItem");
      this.$store.dispatch("setErrors", {});
      this.$store.dispatch('getSupplierCategories', {queryParams: this.$route.query, id: this.$route.params.supplierId, loading: false})
    },
    submit() {
      this.$Progress.start();

      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateSupplierCategory', {
            supplierId: this.$route.params.supplierId,
            supplierCategoryId: this.category.id,
            category_id: this.form.internal_category ? this.form.internal_category.id : "",
            import_products: this.form.import_products
          })
          .then(response => {
            this.$Progress.finish()
            this.close();
          })
          .catch(error => {
            this.$Progress.fail()
          })
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>