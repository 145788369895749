<template>
  <Content :search="false" v-if="supplier">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>{{supplier.name}}</h2>
      </div>
    </template>
    
    <template v-slot:content>
      <div>
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <SupplierDetails :supplier="supplier" />

            <template v-if="supplier.procurements.length > 0">
              <SupplierInvoices :supplier="supplier" />
            </template>
          </ul>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import SupplierDetails from "@/components/Stock/Suppliers/SupplierDetails/SupplierDetails"
import SupplierInvoices from "@/components/Stock/Suppliers/SupplierInvoices/SupplierInvoices"

export default {
  name: "Supplier",
  components: {
    SupplierDetails,
    SupplierInvoices,
  },
  data: () => ({
  }),
  created() {
    this.$store.dispatch('clearSingleSupplier')
    this.$store.dispatch('getSingleSupplier', this.$route.params.supplierId)
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier',
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>