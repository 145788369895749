<template>
    <item-card contentClass="grid grid-cols-12 w-full">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ category.name }}
        </h3>
      </template>

      <template v-slot:buttons>
        <template v-if="!showEditForm">
          <div class="relative w-10 h-10">
            <Button icon="pencil" @click="editItem(category)" />
          </div>
        </template>
      </template>

      <template v-slot:content>

        <template v-if="!showEditForm">
          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-2">
              <item-text title="#" :text="category.id" />
          </div>

          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
              <item-text title="Piegādātāja ID" :text="category.supplier_category_id" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
              <item-text title="Importēt produktus" :text="category.import_products ? 'Jā' : 'Nē'" />
          </div>

          <template v-if="category.internal_category">
              <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
                  <item-text title="BTE kategorija" :text="category.internal_category.name" />
              </div>
          </template>
        </template>
      </template>

      <template v-slot:additional>
        <template v-if="!showEditForm">
          <div class="flex items-center pb-3">
            <Button icon="open-in-new" @click="openPath(category.url)" v-if="category.url"/>
            <p v-if="category.path" class="text-md text-gray-900 dark:text-gray-300">{{category.path}}</p>
          </div>
        </template>

        <template v-if="editableItem.id === category.id">
          <EditSupplierCategory :category="category" @closeForm="showEditForm = false" />
        </template>
      </template>

    </item-card>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditSupplierCategory from "@/components/Stock/Suppliers/Categories/EditSupplierCategory"

export default {
    name: "SingleSupplierCategory",
    components: {
        ItemCard,
        ItemText,
        EditSupplierCategory,
    },
    props: [ 'category' ],
    data() {
      return {
        showEditForm: false,
      }
    },
    computed: {
      ...mapGetters({
        editableItem: 'editableItem'
      })
    },
    methods: {
      openPath(url) {
        window.open(url, '_blank');
      },
      editItem(item){
        this.$store.dispatch('setEditableItem', item)
      },
    }
}
</script>

<style>

</style>