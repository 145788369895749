<template>
  <li>
    <item-card>    
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          {{ supplier.name }}
        </h3>
      </template>

      <template v-slot:buttons>
        <template v-if="this.$userCan('manage_suppliers') && !displayForm('editSupplier')">
          <span class="relative z-0 inline-flex rounded-md ml-3 items-center">

            <template v-if="supplier.has_categories">
              <Button icon="animation-play-outline" @click="openCategories" tooltip="Skatīt kategorijas" />
            </template>

            <Button icon="pencil" @click="showForm('editSupplier')" />

            <template v-if="supplier.allowDelete">
              <Button icon="delete" @click="deleteSupplier" />
            </template>

          </span>
        </template>
      </template>

      <template v-slot:content>
        <template v-if="!displayForm('editSupplier')">
          <item-text title="Adrese" :text="supplier.address"/>
          <item-text title="Banka" :text="supplier.bank ? supplier.bank : 'Nav norādīts'"/>

          <template v-if="supplier.reg_nr">
            <item-text title="Reģ.nr" :text="supplier.reg_nr"/>
          </template>

          <template v-if="supplier.vat_nr">
            <item-text title="PVN nr" :text="supplier.vat_nr"/>
          </template>

          <template v-if="supplier.email">
            <item-text title="E-pasts" :text="supplier.email"/>
          </template>

          <template v-if="supplier.phone">
            <item-text title="Telefons" :text="supplier.phone"/>
          </template>

          <template v-if="supplier.postpay_days">
            <item-text title="Pēcapmaksas dienas" :text="supplier.postpay_days"/>
          </template>

          <item-text title="Eksportam" :text="supplier.is_export ? 'Jā' : 'Nē'"/>

          <item-text title="Valsts" :text="supplier.country ? supplier.country.name : 'Nav norādīta'"/>
        </template>

        <template v-else>
          <EditSupplier :item="supplier"/>
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditSupplier from "@/components/Stock/Suppliers/EditSupplier"

export default {
  name: "SupplierDetails",
  components: {
    ItemCard,
    ItemText,
    EditSupplier,
  },
  props: ["supplier"],
  computed: {
    ...mapGetters({
      loading: 'loading',
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    openCategories() {
      this.$store.dispatch('changeSearch', '')
      this.$router.push(`/stock/suppliers/${this.supplier.id}/categories`)
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    deleteSupplier() {
      if (confirm('Vai esi drošs?')) {
        this.$Progress.start()
        this.$store.dispatch('deleteSupplier', this.supplier.id)
      }
    },
  },
}
</script>

<style>
</style>