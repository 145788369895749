<template>
  <Content>
    <template v-slot:topbar>
      <AddSupplier />
    </template>

    <template v-slot:content>
        <template v-if="suppliers && suppliers.data.length > 0">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in suppliers.data" :key="item.id">
                <SuppliersListItem :item="item" :suppliers="suppliers" />
              </template>
            </ul>
          </div>

          <Pagination :meta="suppliers.meta" :onPageChange="onPageChange" />
        </template>

        <template v-else>
            <p class="py-6 text-center dark:text-gray-300">
              Diemžēl nekas netika atrasts
            </p>
        </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddSupplier from "@/components/Stock/Suppliers/AddSupplier"
import SuppliersListItem from "@/components/Stock/Suppliers/SuppliersListItem"
import Pagination from "@/components/Components/Pagination";

export default {
  name: "StockItems",
  components: {
    AddSupplier,
    SuppliersListItem,
    Pagination,
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    const s = Search.getSearchQuery();
    if (s) {
      this.$store.dispatch('changeSearch', s)
      this.searchSuppliers()
    } else {
      this.$store.dispatch('getSuppliers', Search.getCurrentPage())
    }
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  computed: {
    ...mapGetters({
      suppliers: 'suppliers',
      formsForDisplay: 'formsForDisplay',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchSuppliers()
    }
  },
  methods: {
    searchSuppliers: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getSuppliers', Search.getCurrentPage())
    }, 500),
    onPageChange(page) {
      this.$store.dispatch('getSuppliers', page);
      Search.changePage(this.suppliers.meta.current_page)
    },
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName)
    },
    hideAddSupplierForm() {
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  }
}
</script>

<style>

</style>