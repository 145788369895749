<template>
  <li>
    <item-card :url="`/stock/suppliers/${item.id}`" contentClass="grid grid-cols-12">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          {{item.name}}
        </h3>
      </template>

      <template v-slot:buttons>

        <template v-if="editableItem.id !== item.id && userHavePermission('manage_suppliers')">

          <Button icon="pencil" @click="editItem(item)" />

          <template v-if="item.allowDelete && userHavePermission('manage_suppliers')">
            <Button icon="delete" @click="deleteSupplier(item.id)" />
          </template>
        </template>

      </template>

      <template v-slot:content>
        <template v-if="editableItem.id !== item.id">
          <SuppliersListItemDetails :item="item" />
        </template>
      </template>

      <template v-slot:additional>
        <template v-if="editableItem.id === item.id && userHavePermission('manage_suppliers')">
          <EditSupplier :item="item" :currentPage="suppliers.meta.current_page"/>
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import SuppliersListItemDetails from "@/components/Stock/Suppliers/SuppliersListItemDetails"
import EditSupplier from "@/components/Stock/Suppliers/EditSupplier"

export default {
  name: "SuppliersListItem",
  components: {
    ItemCard,
    SuppliersListItemDetails,
    EditSupplier,
  },
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    })
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suppliers: {
      type: Object,
      required: true
    },
  },
  methods: {
    userHavePermission(permission) {
      return this.$userCan(permission)
    },
    deleteSupplier(itemId) {
      if (confirm('Vai esi drošs?')) {
        this.$Progress.start()
        this.$store.dispatch('deleteSupplier', itemId)
      }
    },
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
  },
}
</script>

<style>

</style>