<template>

  <form v-if="form" class="space-y-6 w-full pb-4">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
      <div class="col-span-1">
        <Input name="name" placeholder="Nosaukums" v-model="form.name" :errors="errors.name" />
      </div>

      <div class="col-span-1">
        <Input name="reg_nr" placeholder="Reģ. nr" v-model="form.reg_nr" :errors="errors.reg_nr" />
      </div>

      <div class="col-span-1">
        <Input name="vat_nr" placeholder="PVN. nr" v-model="form.vat_nr" :errors="errors.vat_nr" />
      </div>

      <div class="col-span-1">
        <Input name="address" placeholder="Adrese" v-model="form.address" :errors="errors.address" />
      </div>

      <div class="col-span-1">
        <Input name="phone" placeholder="Tel. nr" v-model="form.phone" :errors="errors.phone" />
      </div>

      <div class="col-span-1">
        <Input name="fax" placeholder="Fakss" v-model="form.fax" :errors="errors.fax" />
      </div>

      <div class="col-span-1">
        <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email" />
      </div>

      <div class="col-span-1">
        <Input name="bank" placeholder="Banka" v-model="form.bank" :errors="errors.bank" />
      </div>

      <div class="col-span-1">
        <Autocomplete placeholder="Valsts" v-model="form.country" :errors="errors.country"
                      :items="countries" @change="setItemName" :showItems="true"/>
      </div>

      <div class="col-span-1">
        <Input
        type="number" name="postpay_days" placeholder="Pēcapmaksas dienas" v-model="form.postpay_days" :errors="errors.postpay_days" />
      </div>

      <div class="col-span-1 flex items-center pt-5">
        <Checkbox text="Eksportam" v-model="form.is_export" />
      </div>
    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="hideEditItemForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Components/Input";
import Checkbox from "@/components/Components/Checkbox";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Autocomplete from "@/components/Components/Autocomplete";

export default {
  name: "EditSupplier",
  components: {
    Autocomplete,
    Input,
    Checkbox,
    Loading,
  },
  props: ["item", "currentPage"],
  data: () => ({
    form: null
  }),
  created() {
    if (!this.countries) {
      this.$store.dispatch('fetchCountries');
    }
  },
  mounted() {
    this.form = this.item
  },
  computed: {
    ...mapGetters({
      countries: "fetchedCountries",
      loading: "loading",
      errors: 'errors',
    }),
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
        email: {
          rules: ['email']
        },
      }
    },
  },
  methods: {
    hideEditItemForm() {
      this.$store.dispatch("clearEditableItem");
      this.$store.dispatch("removeAllFormsForDisplay");
      this.$store.dispatch("getSingleSupplier", this.item.id);
      this.$store.dispatch("setErrors", {});
    },
    submit() {
        this.$Progress.start();
        if (Validation(this.formValidation, this.form)) {
          this.$store.dispatch("updateSupplier", {
            id: this.form.id,
            name: this.form.name,
            reg_nr: this.form.reg_nr,
            vat_nr: this.form.vat_nr,
            address: this.form.address,
            phone: this.form.phone,
            fax: this.form.fax,
            email: this.form.email,
            bank: this.form.bank,
            postpay_days: this.form.postpay_days,
            is_export: this.form.is_export,
            country_id: this.form.country ? this.form.country.id : null
          });
        } else {
          this.$Progress.fail();
        }
    },
  },
}
</script>
