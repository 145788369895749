<template>
  
  <div class="col-span-12 sm:col-span-4 lg:col-span-2">
    <item-text title="Reģ. nr" :text="`${item.reg_nr ? item.reg_nr : 'Nav norādīts'}`" />
  </div>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-10">
    <item-text title="Tel. nr / E-pasts" :text="`${item.phone ? item.phone : '-'} / ${item.email ? item.email : '-'}`" />
  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SuppliersListItemDetails",
  components: {
    ItemText,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>