<template>
  <li>
    <item-card>    
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          Pēdējie iepirkumi
        </h3>
      </template>

      <template v-slot:content>
        <div class="divide-y divide-gray-200 dark:divide-gray-700 w-full">
          <template v-for="(invoice, index) in supplier.procurements" :key="index">
            <SingleSupplierInvoice :invoice="invoice"/>
          </template>
        </div>
      </template>

    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleSupplierInvoice from "@/components/Stock/Suppliers/SupplierInvoices/SingleSupplierInvoice"

export default {
  name: "SupplierInvoices",
  components: {
    ItemCard,
    SingleSupplierInvoice,
  },
  props: ["supplier"],
}
</script>

<style>
</style>