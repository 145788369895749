<template>
  <Content :filter="true" :showFilterDefault="true">
    <template v-slot:topbar>
      <AddCategory />
    </template>

    <template v-slot:filter>
      <div class="flex flex-wrap items-center">
        <div class="flex">
          <Radio name="import_products" v-model="filter.import_products" :items="[{ name: 'Visas', value: ''}, {name: 'Bez importa', value: 0}, {name: 'Importam', value: 1}]" @change="getItems" />
        </div>
      </div>
    </template>

    <template v-slot:content>
      <template v-if="supplierCategories && supplierCategories.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <h2 class="font-semibold text-xl mb-5 -mt-4 dark:text-gray-300">{{supplierCategories.supplier.name}}</h2>

            <template v-for="category in supplierCategories.data" :key="category.id">
              <SingleSupplierCategory :category="category" />
            </template>
          </ul>
        </div>

        <Pagination :meta="supplierCategories.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddCategory from "@/components/Settings/Category/AddCategory";
import SingleSupplierCategory from "@/components/Stock/Suppliers/Categories/SingleSupplierCategory";
import Pagination from "@/components/Components/Pagination";

import Radio from "@/components/Components/Radio"

export default {
    name: "SupplierCategories",
    components: {
        AddCategory,
        SingleSupplierCategory,
        Pagination,
        Radio,
    },
    data() {
        return {
            queryParams: null,
            filter: {
              import_products: ""
            }
        }
    },
    created() {
        this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if(Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    page: Search.getCurrentPage(),
                    import_products: "",
                    q: this.search
                }
            })
        }

        // Update component data from query prams
        this.setFiltersFromParams()
        this.searchCategories()
    },
    computed: {
        ...mapGetters({
            supplierCategories: "supplierCategories",
            search: 'search',
        })
    },
    watch: {
      search: function () {
        this.searchCategories()
      }
    },
    methods: {
        getItems() {
            this.$router.replace({
                query: {
                    id: this.$route.params.supplierId,
                    import_products : this.filter.import_products,
                    page: 1,
                    q: this.queryParams.q
                }
            })

            this.queryParams = this.$route.query
            this.searchCategories()
        },

        setFiltersFromParams(){
            this.filter.import_products = this.queryParams.import_products;
        },

        searchCategories: debounce(function () {
            this.$store.dispatch('getSupplierCategories', {queryParams: this.queryParams, id: this.$route.params.supplierId})
        }, 500),

        onPageChange(page) {
            this.searchCategories()
        },
    }
}
</script>

<style>

</style>