<template>
  <router-link :to="`/stock/procurements/${invoice.id}`" class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full">
    <div class="py-4 px-3">
      <div class="grid grid-cols-12 w-full">

        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="#" :text="invoice.supplier_uuid" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="Rēķina datums" :text="invoice.procurement_date" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="Iekšējais nr." :text="invoice.uuid" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="Apmaksāt līdz" :text="invoice.due_date" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="Kopā EUR" :text="invoice.total" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="PVN EUR" :text="invoice.vat" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <ItemText title="Summa EUR" :text="invoice.total_with_vat" />
        </div>
        <div class="col-span-12">
          <div class="mt-2 pt-2">
            <ItemText :text="invoice.warehouses" />
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleSupplierInvoice",
  components: {
    ItemText,
  },
  props: ["invoice"]
}
</script>

<style>

</style>